import { Component } from "@angular/core";
import { StorageService, UserService, StorageKeys, environment, HelperService } from "@mypxplat/xplat/core";
import { AppBaseComponent, AppService, AvoService, ReftagService, ScriptService } from "@mypxplat/xplat/web/core";
import { SwitchLanguageComponent } from "@mypxplat/xplat/web/features";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs";
import * as moment from "moment";
import { Location } from "@angular/common";

// xplat

@Component({
  selector: "myp-root",
  templateUrl: "./app.component.html",
})
export class AppComponent extends AppBaseComponent {
  constructor(
    userService: UserService,
    public appService: AppService,
    public avoService: AvoService,
    private _storageService: StorageService,
    private _translateService: TranslateService,
    private _scriptService: ScriptService,
    public reftagService: ReftagService,
    public helperService: HelperService,
    private _location: Location
  ) {
    super(userService);
    this.appService.app = "admin";
    this.userService.appId = "admin";
  }

  ngOnInit() {
    // check if "zendesk" is a url parameter, and if so, add a class to the body
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("zendesk")) {
      document.body.classList.add("zendesk");
    }
    let languageToUse = "en";
    if (this.user && this.user.language_code) {
      languageToUse = this.user.language_code;
    } else {
      const savedLanguage = this._storageService.getItem(StorageKeys.LANGUAGE);
      if (savedLanguage) {
        languageToUse = savedLanguage;
      }
    }
    if (!this.userService.availableLanguages[languageToUse]) languageToUse = "en";
    this._translateService.setDefaultLang(languageToUse);
    moment.locale(languageToUse);
    this._translateService.use(languageToUse);
    this.appService.initTheme();
    this.helperService.backPressed$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this._location.back();
    });
  }
}
